.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

@media print {
  .pagebreak {
    break-before: page;
  }
}

.ContactInfo {
  margin-bottom: 0;
}

.Resume {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 90%;
  max-width: 1000px;
  padding: 1em;
}

.ProjectName {
  /* font-style: italic; */
  /* font-weight: bold; */
  /* margin-top: 0.5em;
  margin-bottom: 0.5em; */
}

.smashdexTitleText {
  font-family: 'Advent Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  letter-spacing: 0.5em;
  text-decoration: none;
  text-shadow: 0 0 10px #fff;
}

.smashdexHeadline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

ul {
  margin-top: 8px;
}

img {
  max-height: 100%;
  max-width: 100%;
}

header {
  font-size: calc(2em + 2vmin);
}

.header-date {
  display: flex;
  justify-content: space-between;
}

.blue-bg {
  background-color: rgb(221, 240, 242);
  width: 100%;
}

.dark-blue-bg {
  background-color: rgb(206, 219, 230);
  width: 100%;
}

@media screen {
  .blue-bg {
    padding-left: 6px;
  }

  .dark-blue-bg {
    padding-left: 6px;
  }
}

h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 16.5px;
}

h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  /* text-transform: uppercase; */
  /* font-variant-caps: small-caps; */
}

h4 {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

header {
  /* font-variant-caps: small-caps; */
}

p {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

@media (min-width: 600px) {
  p,
  .paragraph-margin {
    margin-left: 2em;
  }
}

a {
  color: rgb(0, 0, 190);
}

a:visited {
  color: rgb(0, 0, 190);
}

ul ul {
  margin-top: 2px;
  margin-bottom: 4px;
}

.print {
  display: none;
}

.screen {
  display: initial;
}

@media print {
  body {
    font-size: 0.7rem;
    /* margin: 2mm; */
    /* padding: 1mm; */
  }

  h3 {
    font-size: 1em;
  }

  .print {
    display: initial;
  }

  .screen {
    display: none;
  }
}

@page {
  size: letter;
  /* auto is the initial value */
  margin: 0mm;
  margin-top: 2%;
  /* this affects the margin in the printer settings */
}
